/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/main.css';

// function to jump to the correct scroll position when following a link with a anchor
function scrollToAnchor(location) {
  const { hash } = location;

  if (!hash) return;

  window.onload = function () {
    window.requestAnimationFrame(() => {
      const anchor = document.querySelector(hash);

      if (!anchor) return;

      const offset = anchor.getBoundingClientRect().top + window.scrollY;

      window.scroll({ top: offset });
    });
  };
}

export const onRouteUpdate = ({ location }) => {
  scrollToAnchor(location);
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state && location.state.preventScroll === true) {
    return false;
  }

  return true;
};

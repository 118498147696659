module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#0B1F22","theme_color":"#0B1F22","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2408cce5a31bd5ffb1e14cf31b4652eb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"url":"https://headsupai.dreamhosters.com/graphql","html":{"generateWebpImages":true,"fallbackImageMaxWidth":1920,"imageQuality":80,"useGatsbyImage":true,"imageMaxWidth":null,"createStaticFiles":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"develop":{"nodeUpdateInterval":5000,"hardCacheMediaFiles":false,"hardCacheData":false},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://headsupai.dreamhosters.com/graphql","auth":{"htaccess":{"username":"headsup","password":"3!0ppZ0Cd2nB"}},"api":{"key":"ck_7f28c15246388e62430bd80bfff12becf21181ee","secret":"cs_420ea0ee2e98b4a3f6b04b8731c9b7c3dfcefb3c"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MG3JRMR","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
